var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"change-bank-card"},[_c('div',{staticClass:"go-back"},[_c('div',{on:{"click":_vm.onClickLeft}},[_c('van-icon',{staticClass:"icon-left",attrs:{"name":"arrow-left"}}),_c('span',[_vm._v(_vm._s(_vm.isCardPay ? "支付银行卡": _vm.isEdit ? '更换银行卡' : '绑定银行卡'))])],1)]),_c('div',{staticClass:"card-content"},[_vm._m(0),_c('div',{staticClass:"content-form"},[_c('div',{staticClass:"content-item"},[_c('van-form',[_c('van-field',{attrs:{"readonly":"","clickable":"","name":"bank_name","value":_vm.bank_name,"label":"银行","placeholder":"点击选择银行","input-align":"right"},on:{"click":function($event){_vm.showSupportBank = true}}}),_c('van-field',{attrs:{"label":"持卡人","placeholder":"请输入持卡人姓名","input-align":"right"},on:{"input":_vm.oncardholderInput},model:{value:(_vm.card_info.cardholder),callback:function ($$v) {_vm.$set(_vm.card_info, "cardholder", $$v)},expression:"card_info.cardholder"}}),_c('van-field',{attrs:{"label":"借记卡号","placeholder":"请输入借记卡号","input-align":"right"},on:{"input":_vm.onCardNumberInput},model:{value:(_vm.card_info.bank_card_no),callback:function ($$v) {_vm.$set(_vm.card_info, "bank_card_no", $$v)},expression:"card_info.bank_card_no"}}),(_vm.showIDCardNo)?_c('van-field',{attrs:{"label":"持卡人身份证号","placeholder":"请输入持卡人身份证号","input-align":"right"},on:{"input":_vm.onCardNoInput},model:{value:(_vm.idCardNO),callback:function ($$v) {_vm.idCardNO=$$v},expression:"idCardNO"}}):_vm._e(),_c('van-field',{attrs:{"label":"预留手机号","placeholder":"请输入预留手机号","input-align":"right","maxlength":"11","type":"tel","rules":[
              { validator: _vm.verifyPhone, message: '请输入正确的手机号码' },
            ]},on:{"input":_vm.onPhoneInput},model:{value:(_vm.card_info.reserve_mobile),callback:function ($$v) {_vm.$set(_vm.card_info, "reserve_mobile", $$v)},expression:"card_info.reserve_mobile"}}),_c('van-field',{attrs:{"name":"code","label":"验证码","placeholder":"验证码","maxlength":"6","rules":[{ validator: _vm.verifyCode, message: '请填写6位验证码' }]},on:{"input":_vm.onCodeInput},scopedSlots:_vm._u([{key:"button",fn:function(){return [(!_vm.codeState)?_c('span',{staticStyle:{"color":"#1677ff"},on:{"click":_vm.getCode}},[_vm._v("获取验证码")]):_vm._e(),(_vm.codeState)?_c('span',{staticClass:"code",staticStyle:{"color":"#bebebe"}},[_vm._v("还剩"+_vm._s(_vm.codeTime)+"秒")]):_vm._e()]},proxy:true}]),model:{value:(_vm.smsCode),callback:function ($$v) {_vm.smsCode=$$v},expression:"smsCode"}})],1)],1),_c('div',{staticStyle:{"margin":"10px 16px"}},[(
            _vm.supportBankEmpty &&
            _vm.cardholderEmpty &&
            _vm.cardNumberEmpty &&
            _vm.phoneEmpty &&
            _vm.codeEmpty &&
            !_vm.loading
          )?_c('div',{staticClass:"next-step-button",staticStyle:{"background":"#1677ff"},on:{"click":_vm.onSubmit}},[_vm._v(" 确定 ")]):(_vm.loading)?_c('div',{staticClass:"next-step-button"},[_vm._v("绑定中...")]):_c('div',{staticClass:"next-step-button"},[_vm._v("确定")])])])]),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showSupportBank),callback:function ($$v) {_vm.showSupportBank=$$v},expression:"showSupportBank"}},[_c('van-picker',{attrs:{"show-toolbar":"","value-key":"bank_name","columns":_vm.supportBankList},on:{"confirm":_vm.onSupportBank,"cancel":function($event){_vm.showSupportBank = false}}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-title"},[_c('div',{staticClass:"title-text"},[_c('div',{staticClass:"title"},[_vm._v("银行卡信息")])])])
}]

export { render, staticRenderFns }