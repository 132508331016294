<template>
  <div class="change-bank-card">
    <div class="go-back">
      <div @click="onClickLeft">
        <van-icon name="arrow-left" class="icon-left" />
        <span>{{isCardPay ? "支付银行卡": isEdit ? '更换银行卡' : '绑定银行卡'}}</span>
      </div>
    </div>
    <div class="card-content">
      <div class="content-title">
        <div class="title-text">
          <div class="title">银行卡信息</div>
        </div>
      </div>
      <div class="content-form">
        <div class="content-item">
          <van-form>
            <van-field
              readonly
              clickable
              name="bank_name"
              :value="bank_name"
              label="银行"
              placeholder="点击选择银行"
              @click="showSupportBank = true"
              input-align="right"
            />
            <van-field
              v-model="card_info.cardholder"
              label="持卡人"
              placeholder="请输入持卡人姓名"
              input-align="right"
              @input="oncardholderInput"
            />
            <van-field
              v-model="card_info.bank_card_no"
              label="借记卡号"
              placeholder="请输入借记卡号"
              input-align="right"
              @input="onCardNumberInput"
            />
            <van-field 
              v-if="showIDCardNo"
              v-model="idCardNO"
              label="持卡人身份证号"
              placeholder="请输入持卡人身份证号"
              input-align="right"
              @input="onCardNoInput">
              
            </van-field>
            <van-field
              v-model="card_info.reserve_mobile"
              label="预留手机号"
              placeholder="请输入预留手机号"
              input-align="right"
              maxlength="11"
              type="tel"
              @input="onPhoneInput"
              :rules="[
                { validator: verifyPhone, message: '请输入正确的手机号码' },
              ]"
            />
            <van-field
              v-model="smsCode"
              name="code"
              label="验证码"
              placeholder="验证码"
              maxlength="6"
              @input="onCodeInput"
              :rules="[{ validator: verifyCode, message: '请填写6位验证码' }]"
            >
              <template #button>
                <span style="color: #1677ff" v-if="!codeState" @click="getCode"
                  >获取验证码</span
                >
                <span class="code" v-if="codeState" style="color: #bebebe"
                  >还剩{{ codeTime }}秒</span
                >
              </template>
            </van-field>
          </van-form>
        </div>
        <div style="margin: 10px 16px">
          <div
            class="next-step-button"
            style="background: #1677ff"
            @click="onSubmit"
            v-if="
              supportBankEmpty &&
              cardholderEmpty &&
              cardNumberEmpty &&
              phoneEmpty &&
              codeEmpty &&
              !loading
            "
          >
            确定
          </div>
          <div class="next-step-button" v-else-if="loading">绑定中...</div>
          <div class="next-step-button" v-else>确定</div>
        </div>
      </div>
    </div>
    <van-popup v-model="showSupportBank" position="bottom">
      <van-picker
        show-toolbar
        value-key="bank_name"
        :columns="supportBankList"
        @confirm="onSupportBank"
        @cancel="showSupportBank = false"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  listSupportBank,
  bindCardSmsCode,
  confirmBindCard,
} from "@/api/bank.js";
import { Toast } from "vant";
export default {
  data() {
    return {
      isEdit: true,
      isCardPay: false,
      loading:false,
      bank_name:'',
      supportBankList:[],
      card_info:{},
      supportBankEmpty:false,
      showSupportBank:false,
      cardholderEmpty:false,
      cardNumberEmpty:false,
      phoneEmpty:false,
      codeEmpty:false,
      smsCode:"",
      codeState: false,
      codeTime: 60,
      timer: null, 
      order_id:"",
      
      showIDCardNo: false,
      idCardNO: "",
      userID: ""
    };
  },

  mounted() {
    if(this.$route.query.type == "bind"){
      this.isEdit = false
    }
    if(this.$route.query.type == "cardPay"){
      this.isCardPay = true
    }
    if (localStorage.getItem("manage_temp_no")) {
      this.userID = localStorage.getItem("manage_temp_no")
      this.showIDCardNo = true
    }
    this.getSupportBank()
  },

  methods: {
    async onSubmit(){
      var that = this;
      const confirmCardInfo = {
        orderId: that.order_id,
        smsCode: that.smsCode,
      };
      const { code } = await confirmBindCard(confirmCardInfo);
      if (code == 200) {
        that.isCardPay ? that.$router.go(-1) : that.$router.push("/myBankCard");
      }
    },
    verifyCode(val) {
      var rule = val.length == 6
      if(rule){
        this.codeEmpty = true;
      } else {
         this.codeEmpty = false;
      }
      return rule;
    },
    onCodeInput(value) {
      this.smsCode = value;
      if (this.smsCode.length != 6) {
        this.codeEmpty = false;
      } else {
        this.codeEmpty = true;
      }
    },
    async getCode() {
      if (
        this.supportBankEmpty &&
        this.cardholderEmpty &&
        this.cardNumberEmpty &&
        this.phoneEmpty
      ) {
        var that = this;
        this.codeState = true;
        const parmes = {
          bankId: this.card_info.support_bank_id,
          bankCardNo: (this.card_info.bank_card_no || "").replace(/\s/g, ''),
          cardholder: this.card_info.cardholder,
          reserveMobile: this.card_info.reserve_mobile,
        };
        if (this.showIDCardNo) {
          parmes.idCardNo = this.idCardNO
          parmes.userID = this.userID
        }
        if (this.showIDCardNo) {
          console.log(parmes)
          return
        }
        const { code, data } = await bindCardSmsCode(parmes);
        if (code == 200) {
          that.order_id = data.order_id;
          that.timer = setInterval(() => {
            if (that.codeTime == 0) {
              clearInterval(that.timer);
              that.codeState = false;
              that.codeTime = 60;
            } else {
              that.codeTime--;
            }
          }, 1000);
        } else {
          that.codeState = false;
          that.codeTime = 60;
        }
      } else {
        Toast("请先完善绑卡信息");
      }
    },
    onPhoneInput(value) {
      this.card_info.reserve_mobile = value;
    },
    verifyPhone(val) {
      // var rule =
      //   /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(val);
      var rule = /^1\d{10}$/.test(val);
      if (rule) {
        this.phoneEmpty = true;
      } else {
        this.phoneEmpty = false;
      }
      return rule;
    },
    onCardNumberInput(value) {
      this.card_info.bank_card_no = value;
      if (this.card_info.bank_card_no == "") {
        this.cardNumberEmpty = false;
      } else {
        this.cardNumberEmpty = true;
      }
    },
    onCardNoInput(val) {
      this.idCardNO = val
    },
    oncardholderInput(value) {
      this.card_info.cardholder = value;
      if (this.card_info.cardholder == "") {
        this.cardholderEmpty = false;
      } else {
        this.cardholderEmpty = true;
      }
    },
    async getSupportBank() {
      const { code, data } = await listSupportBank();
      if (code == 200) {
        this.supportBankList = data;
      }
    },
    onSupportBank(value) {
      this.bank_name = value.bank_name;
      this.card_info.support_bank_id = value.bank_id;
      this.showSupportBank = false;
      if (this.card_info.support_bank_id == "") {
        this.supportBankEmpty = false;
      } else {
        this.supportBankEmpty = true;
      }
    },
    onClickLeft() {
      this.isCardPay ?  this.$router.go(-1) : this.$router.push("/myBankCard");
    },
  },
};
</script>

<style lang="scss" scoped>
.change-bank-card {
  height: 100%;
  width: 100%;
  background: #f5f5f5;
  .go-back {
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 30px;
    width: 100%;
    background: #fff;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    height: 100px;
  }
  .card-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .content-title {
      margin: 30px 30px;
      display: flex;
      justify-content: center;
      .title-text {
        flex: 1;
        .title {
          font-size: 30px;
        }
        .hint {
          font-size: 24px;
          color: #666666;
        }
      }
    }
    .content-form {
      flex: 1;
      overflow-y: auto;
      padding: 0px 30px;
    }
    .content-item {
      margin-bottom: 20px;
      background: #fff;
      box-shadow: 0px 0px 10px 0px #f5f5f5;
      border-radius: 20px;
      padding: 15px 0px;
      font-size: 30px;
      color: #333;
    }
    .next-step-button {
        width: 100%;
        height: 90px;
        background: #708eb8;
        box-shadow: 0px 7px 13px 0px rgba(112, 142, 184, 0.32);
        border-radius: 45px;
        font-size: 36px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  }
}
</style>